import React, { useRef, useEffect, useState } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

import SvgIconHandler from '../../../atoms/svg-icon-handler';
import './navbar-search.m.css';
import CloseIcon from '../../../atoms/icons/close';

const NavbarSearch = () => {
  const inputRefDesktop = useRef(null);
  const suggestionRef = useRef();

  const [toggleSearch, setToggleSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  const [suggestions, setSuggestion] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [searchtext, setSearchtext] = useState('');

  useEffect(() => {
    inputRefDesktop.current.addEventListener('focus', () => setShowSuggestion(true));
    window.onclick = function (e) {
      if (
        !suggestionRef?.current?.contains(e?.target) &&
        e?.target !== inputRefDesktop?.current &&
        document.activeElement !== inputRefDesktop.current
      ) {
        setShowSuggestion(false);
      }
    };
    return () =>
      inputRefDesktop.current.removeEventListener('focus', () => setShowSuggestion(true));
  }, []);

  const getStory = (slug) => {
    window.location.href = `/${slug}`;
  };

  const getSuggestion = () => {
    setSearchtext(inputRefDesktop?.current?.value?.toLowerCase()?.trim());
    fetch(
      `/api/v1/search?q=${inputRefDesktop?.current?.value?.toLowerCase()}&fields=headline,id,author-name,slug&limit=100`
    )
      .then((res) => res.json())
      .then((res) => setSuggestion(res.results.stories));
  };

  const getSuggestionDebounced = debounce(getSuggestion, 700);

  const inputValue = (inputRefDesktop?.current?.value || '').trim();
  const isInputEmpty = inputValue === '';

  const handleSearchClose = () => {
    inputRefDesktop.current.value = '';
    setToggleSearch(false);
    setSuggestion([])
  };

  const handleSearchOpen = () => {
    setToggleSearch(true);
  };

  useEffect(() => {
    if (toggleSearch) {
      inputRefDesktop?.current.focus();
    }
  }, [toggleSearch]);

  return (
    <>
      <div
        className="desktop-only"
        styleName={`search-desktop ${toggleSearch ? 'searchBarOpen' : 'searchBarClose'}`}
      >
        <form action="/search" method="get">
          <input
            ref={inputRefDesktop}
            name="q"
            type="text"
            onChange={getSuggestionDebounced}
            autoComplete="off"
            placeholder="Search here..."
          />
          {toggleSearch ? (
            <>
              {loading ? (
                <div styleName="loading-container">
                  <div styleName="loading" />
                </div>
              ) : (
                <div onClick={handleSearchClose} styleName="close-icon-search">
                  <SvgIconHandler type="close_original" width="11" height="11" color="grey" />
                </div>
              )}
            </>
          ) : null}
          <div styleName="search-btn-wrapper">
            <button type="submit" disabled={isInputEmpty} />
            <div onClick={handleSearchOpen} className={toggleSearch ? 'pointer-none' : ''} styleName="search-icon-wrapper">
              <SvgIconHandler type="search_icon" width="17" height="17" color="white" />
            </div>
          </div>
        </form>
      </div>
      {suggestions && suggestions?.length > 0 && showSuggestion && (
        <>
          <div styleName="suggestions-autofill-container" ref={suggestionRef}>
            {searchtext &&
              suggestions?.map((item) => {
                if (
                  item?.headline?.toLowerCase()?.includes(searchtext?.toLowerCase()) ||
                  get(item, ['author-name'], '')?.toLowerCase()?.includes(searchtext?.toLowerCase())
                ) {
                  return (
                    <div
                      onClick={() => getStory(item?.slug)}
                      key={item?.id}
                      styleName="suggestionBox"
                    >
                      <span>{item?.headline}</span>
                      <small>{get(item, ['author-name'], '')}</small>
                    </div>
                  );
                }
              })}
          </div>
        </>
      )}
    </>
  );
};

export { NavbarSearch };
